/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadLeftIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M15 18.8c-.2 0-.4-.1-.5-.2l-6-6c-.3-.3-.3-.8 0-1.1l6-6c.3-.3.8-.3 1.1 0s.3.8 0 1.1L10.1 12l5.5 5.5c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2z" />
	</svg>
))
ArrowheadLeftIconSvg.displayName = 'ArrowheadLeftIconSvg'

const ArrowheadLeftIcon = forwardRef((props, ref) => <Icon component={ArrowheadLeftIconSvg} ref={ref} {...props} />)
ArrowheadLeftIcon.displayName = 'ArrowheadLeftIcon'

ArrowheadLeftIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadLeftIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadLeftIcon
export { ArrowheadLeftIconSvg }
