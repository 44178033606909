import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useIntlDefault } from '@bluheadless/ui-logic/src/providers/intl/intl-default'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import { WITHOUT_LAYOUT } from '@bluheadless/ui/src/layouts/variants'
import CountryPickerPageComponent from '@bluheadless/ui/src/page-components/country-picker/country-picker'
import groupBy from 'lodash/groupBy'
import Head from 'next/head'
import { useEffect } from 'react'
import { IntlProvider, useIntl } from 'react-intl'
import { onIntlError } from '@bluheadless/ui-logic/src/providers/intl/intl'

const CountryPicker = () => {
	const {
		locale,
		seoMeta: { title },
	} = useConfig()

	const defaultTranslations = useIntlDefault()

	const { formatMessage } = useIntl()

	const { pageViewDetails } = useTrackingsActions()
	const { entityType: pageContext, __bhEntityId: pageId } = usePageContext()

	const [{ isLoggedIn }] = useUser()

	useEffect(() => {
		pageViewDetails({ pageType: 'country-picker', params: { isLoggedIn, locale }, pageContext, pageId })
	}, [isLoggedIn, pageViewDetails, locale, pageContext, pageId])

	return (
		<IntlProvider messages={defaultTranslations} locale="en-US" defaultLocale="en-US" onError={onIntlError}>
			<Head>
				<title>{formatMessage({ id: 'country_picker_meta_title' }, { metaTitle: title })}</title>
				<meta name="robots" content="noindex,nofollow" />
			</Head>
			<CountryPickerPageComponent />
		</IntlProvider>
	)
}

export default CountryPicker

export const getServerSideProps = async ({ req }) => {
	const storesByGroupId = groupBy(req?.__bh__config?.availableStores, 'groupId') || []
	const isSingleWebsite = Object.keys(storesByGroupId).length === 1
	const isMultiLanguage =
		!isSingleWebsite || (isSingleWebsite && storesByGroupId[Object.keys(storesByGroupId)[0]].length > 1)

	if (!isMultiLanguage) {
		return {
			notFound: true,
		}
	}

	return {
		props: {},
	}
}

CountryPicker.withLayout = WITHOUT_LAYOUT
